import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Sobre nosotros`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Años de experiencia en el sector tecnológico y de emprendimiento nos respaldan como un proveedor de servicios de nivel internacional.`}</p>
    </blockquote>
    <p>{`Nuestra misión es ayudar a emprendedores mexicanos a lograr sus sueños y crecer sus negocios, a través de soluciones web a su medida. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      