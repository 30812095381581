/** @jsx jsx */
import { jsx } from "theme-ui";
import Divider from "../elements/divider";
import Inner from "../elements/inner";
import Content from "../elements/content";
import Svg from "./svg";
import { UpDown, UpDownWide } from "../styles/animations";
import React from "react";
//import Enlace from "./../img/enlace.png";
// @ts-ignore
import Intro from "../sections/intro"



const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDown>
        <Svg
          icon="triangle"
          hiddenMobile
          width={48}
          stroke
          color="icon_orange"
          left="10%"
          top="20%"
        />
        <Svg
          icon="hexa"
          width={48}
          stroke
          color="icon_red"
          left="60%"
          top="70%"
        />
        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_blue"
          left="80%"
          top="10%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="50%"
        />
        <Svg
          icon="circle"
          width={16}
          color="icon_darker"
          left="70%"
          top="90%"
        />
        <Svg
          icon="triangle"
          width={16}
          stroke
          color="icon_darkest"
          left="30%"
          top="65%"
        />
        <Svg
          icon="cross"
          width={16}
          stroke
          color="icon_pink"
          left="28%"
          top="15%"
        />
        <Svg
          icon="circle"
          width={6}
          color="icon_darkest"
          left="75%"
          top="10%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={24}
        color="icon_darker"
        left="5%"
        top="70%"
      />
      <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <Svg
        icon="upDown"
        hiddenMobile
        width={24}
        color="icon_darker"
        left="40%"
        top="80%"
      />
      <Svg
        icon="triangle"
        width={8}
        stroke
        color="icon_darker"
        left="25%"
        top="5%"
      />
      <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <Svg
        icon="box"
        hiddenMobile
        width={64}
        color="icon_purple"
        left="5%"
        top="90%"
      />
      <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <Svg
        icon="hexa"
        width={16}
        stroke
        color="icon_darker"
        left="10%"
        top="50%"
      />
      <Svg
        icon="hexa"
        width={8}
        stroke
        color="icon_darker"
        left="80%"
        top="70%"
      />
    </Divider>
    <Content
      sx={{ variant: `texts.bigger`, fontSize: 5 }}
      speed={0.4}
      offset={offset}
      factor={factor}
    >
      <Inner>
        <div sx={{ height: 30, margin: 100 }}/>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          viewBox="0 0 1280 720"
          enable-background="new 0 0 1280 720"
          xmlSpace="preserve"
          
        >
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M429.000000,589.999939 
	C293.198059,589.999939 157.896118,589.999939 22.297104,589.999939 
	C22.297104,437.399994 22.297104,285.004486 22.297104,132.304443 
	C434.570129,132.304443 846.950012,132.304443 1259.664795,132.304443 
	C1259.664795,165.861755 1259.664795,199.596634 1259.664795,233.716049 
	C887.123291,233.716049 514.408936,233.716049 141.347015,233.716049 
	C141.347015,258.832733 141.347015,283.571198 141.347015,308.713440 
	C185.507843,308.713440 229.911163,308.713440 274.656128,308.713440 
	C274.656128,342.841553 274.656128,376.569763 274.656128,410.757996 
	C230.369995,410.757996 185.974060,410.757996 141.289551,410.757996 
	C141.289551,436.618286 141.289551,462.022278 141.289551,487.856628 
	C514.263611,487.856628 887.304626,487.856628 1260.672363,487.856628 
	C1260.672363,522.011353 1260.672363,555.739319 1260.672363,589.999939 
	C983.654053,589.999939 706.577026,589.999939 429.000000,589.999939 
z"
          />
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M462.000153,269.009552 
	C462.000153,264.533234 462.000153,260.555359 462.000153,256.289734 
	C480.953308,256.289734 499.689606,256.289734 518.713257,256.289734 
	C518.713257,327.957336 518.713257,399.687073 518.713257,471.753265 
	C517.706299,471.830536 516.602844,471.987946 515.499207,471.988983 
	C498.835205,472.004761 482.170776,472.054413 465.507935,471.915649 
	C463.855652,471.901855 461.613159,471.187805 460.657288,469.990387 
	C448.627167,454.920502 436.814728,439.677216 424.882599,424.528717 
	C414.531464,411.387421 404.043671,398.353607 393.717621,385.192780 
	C383.329285,371.952637 373.067047,358.613556 362.745972,345.320648 
	C361.856537,344.175171 360.940216,343.050568 359.121277,340.767853 
	C359.121277,385.446594 359.121277,428.468689 359.121277,471.744568 
	C339.978760,471.744568 321.242950,471.744568 302.253906,471.744568 
	C302.253906,399.974121 302.253906,328.244690 302.253906,256.000153 
	C310.386169,256.000153 318.491516,256.000153 326.596893,256.000153 
	C334.762238,256.000153 342.974304,256.536835 351.078308,255.831833 
	C357.664795,255.258850 361.571960,257.860077 365.361450,262.802216 
	C380.667725,282.764252 396.305664,302.471710 411.784851,322.301514 
	C426.981995,341.769958 442.126160,361.279755 457.323669,380.747925 
	C458.608948,382.394348 460.121765,383.863159 462.000153,385.934082 
	C462.000153,346.877594 462.000153,308.192810 462.000153,269.009552 
z"
          />
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M738.003540,381.978027 
	C748.364502,354.509735 758.686584,327.439026 769.010986,300.369232 
	C774.339233,286.398956 779.620361,272.410065 785.099854,258.499329 
	C785.537781,257.387756 787.352783,256.114471 788.536560,256.105743 
	C808.519348,255.958694 828.503601,256.000549 848.357849,256.000549 
	C875.760925,328.000336 903.057922,399.721375 930.500427,471.824829 
	C909.682678,471.824829 889.558044,471.824829 869.447693,471.824829 
	C865.602234,460.463806 861.880554,449.157074 857.844849,437.963501 
	C857.373352,436.655609 855.174500,435.133881 853.766846,435.124329 
	C828.442383,434.953217 803.116455,434.999847 777.433899,434.999847 
	C773.622864,446.199249 769.889038,457.442047 765.887939,468.588928 
	C765.386658,469.985352 763.485046,471.841461 762.214600,471.855133 
	C742.897827,472.063507 723.578186,471.999969 703.680115,471.999969 
	C715.216125,441.844086 726.590759,412.110016 738.003540,381.978027 
M824.580383,342.913727 
	C822.277344,336.271667 819.974304,329.629578 817.671204,322.987518 
	C817.316284,323.032654 816.961304,323.077789 816.606323,323.122925 
	C808.723328,345.621948 800.840332,368.120941 792.888489,390.816467 
	C809.233215,390.816467 825.026733,390.816467 841.367798,390.816467 
	C835.825562,374.864136 830.398315,359.242767 824.580383,342.913727 
z"
          />
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M1250.910156,339.001587 
	C1253.051147,339.001587 1254.703735,339.001587 1256.677734,339.001587 
	C1256.677734,355.224182 1256.677734,371.293427 1256.677734,387.766235 
	C1236.102905,387.766235 1215.364990,387.766235 1194.314453,387.766235 
	C1194.314453,399.898254 1194.314453,411.636444 1194.314453,423.778137 
	C1216.238525,423.778137 1238.309326,423.778137 1260.688599,423.778137 
	C1260.688599,439.910309 1260.688599,455.646912 1260.688599,471.692596 
	C1219.750488,471.692596 1178.685303,471.692596 1137.309814,471.692596 
	C1137.309814,400.094177 1137.309814,328.367126 1137.309814,256.320007 
	C1178.223267,256.320007 1219.288574,256.320007 1260.676758,256.320007 
	C1260.676758,271.885895 1260.676758,287.622742 1260.676758,303.762634 
	C1238.773315,303.762634 1216.702759,303.762634 1194.316772,303.762634 
	C1194.316772,315.560425 1194.316772,326.965454 1194.316772,339.001587 
	C1212.916260,339.001587 1231.669067,339.001587 1250.910156,339.001587 
z"
          />
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M1001.006104,339.136108 
	C989.714478,365.591675 998.297058,393.979767 1015.674500,408.933044 
	C1038.413086,428.499573 1074.121216,430.204285 1102.509155,399.639069 
	C1102.509155,422.449463 1102.509155,444.748444 1102.509155,468.527161 
	C1091.118286,471.250732 1079.210693,474.866455 1067.036865,476.833099 
	C1047.553345,479.980560 1028.283203,478.370239 1009.720520,471.014526 
	C997.698120,466.250458 986.712585,459.903076 976.824158,451.433777 
	C957.942871,435.262421 945.630859,415.114044 939.689087,391.142914 
	C936.011841,376.307709 935.596252,361.327942 937.764160,346.013641 
	C940.377441,327.553467 947.229431,311.110870 958.075439,296.288116 
	C975.357971,272.668701 998.464172,257.504730 1027.262451,252.236694 
	C1038.829468,250.120743 1050.925415,249.726257 1062.704712,250.251663 
	C1075.940796,250.842041 1088.627686,255.143738 1100.932617,260.119995 
	C1101.853394,260.492371 1102.900269,261.995605 1102.905396,262.978210 
	C1103.017700,284.731995 1102.991211,306.486481 1102.991211,328.537292 
	C1096.950317,323.526215 1091.691406,318.286102 1085.609131,314.303009 
	C1073.367676,306.286377 1059.913574,303.507416 1045.179443,305.804779 
	C1024.467407,309.034149 1010.163330,320.280670 1001.006104,339.136108 
z"
          />
          <path
            fill="#FAF8F8"
            opacity="1.000000"
            stroke="none"
            d="
M619.999939,323.000000 
	C619.999939,356.805756 619.999939,390.111542 619.999939,423.824524 
	C642.612305,423.824524 665.016296,423.824524 687.708618,423.824524 
	C687.708618,439.963684 687.708618,455.699432 687.708618,471.718445 
	C646.365906,471.718445 604.966736,471.718445 563.284241,471.718445 
	C563.284241,400.038544 563.284241,328.310242 563.284241,256.290955 
	C581.952393,256.290955 600.688354,256.290955 619.999939,256.290955 
	C619.999939,278.313690 619.999939,300.406830 619.999939,323.000000 
z"
          />
        </svg>

        <p
          sx={{
            background: "linear-gradient(to right, #09a7df 0%, #6a5bcd 100%)",
            borderRadius: "md",
            padding: 0
          }}
        >
           Somos <strong sx={{ fontSize: 5 }}>expertos</strong> en construir{" "}
          
           tus{" "}
          <strong sx={{ fontSize: 5 }}>sueños digitales</strong>.
        </p>
        <Intro style={{ color: "black" }}/>
      </Inner>
    </Content>
  </div>
);

export default Hero;
