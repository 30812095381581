import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Servicios`}</h2>
    <ProjectCard title="Desarrollo Web" link="" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Si tú lo puedes soñar, nuestros ingenieros lo pueden construir. ¡Digitaliza tu negocio, y expande tus fronteras!
    </ProjectCard>
    <ProjectCard title="Diseño de Marca" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Haz que tus productos y servicios sean únicos. Te ayudamos a diferenciarte de la competencia y a desarrollar tu branding.
    </ProjectCard>
    <ProjectCard title="Fotografía de Producto y Creación de Contenido" link="https://www.lekoarts.de/garden/adding-a-draft-feature-to-gatsby?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Una imagen dice más que mil palabras, que tu contenido hable lo mejor de ti. Nuestro equipo de fotografía captura la magia en tu negocio, ¡listo para compartir en redes sociales!
    </ProjectCard>
    <ProjectCard title="SEO y Marketing Digital" link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  ¡Todos necesitamos una ayuda con esos seguidores! Nos aseguramos que tu prescencia en Google, Facebook e Instagram refleje tu mejor lado.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      